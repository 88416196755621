import React from 'react';
import Layout from '../../components/Layout';
import blogd2 from '../../assets/images/oandg.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='blog-one__single'>             
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                5 days Interactive Gynaecologic Oncology Workshop
                </h2>
                <p className='blog-one__text'>
                  The faculty of Obstetrics & Gynaecology, National Postgraduate Medical College of Nigeria in collaboration with International Gynaecologic Cancer Society and Lagos University Teaching Hospital, Lagos cordially invites you to a 5 days interactive Gynaecologic Oncology workshop<br/>
                  <img src={blogd2}></img>
                </p>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='5 day Oncology Workshop | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
